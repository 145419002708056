<template>
  <div v-if="computedShowToggle" class="toggle-occurrence-container">
    <div class="toggle-occurence">
      <div v-if="computedShowQuarterlyFirst" class="toggle-container">
        <div class="toggle-buttons">
          <div @click="handlePlanChange('quarterly')" class="ocurrence-quarterly" :class="{ active: computedSelectedPlan === 'quarterly' }">
            {{ $t('components.plans.gold.card.header.occurrence.quarterly') }}
          </div>
          <div @click.once="showModal()" @click="handlePlanChange('monthly')" class="ocurrence-monthly" :class="{ active: computedSelectedPlan === 'monthly' }">
            {{ $t('components.plans.gold.card.header.occurrence.monthly') }}
          </div>
          <div class="slider" :class="{ 'slide-right': computedSelectedPlan === 'monthly' }"></div>
        </div>
      </div>
      <div v-else class="toggle-container">
        <div class="toggle-buttons">
          <div @click="handlePlanChange('monthly')" class="ocurrence-monthly" :class="{ active: computedSelectedPlan === 'monthly' }">
            {{ $t('components.plans.gold.card.header.occurrence.monthly') }}
          </div>
          <div @click="handlePlanChange('quarterly')" class="ocurrence-quarterly" :class="{ active: computedSelectedPlan === 'quarterly' }">
            {{ $t('components.plans.gold.card.header.occurrence.quarterly') }}
          </div>
          <div class="slider" :class="{ 'slide-right': computedSelectedPlan === 'quarterly' }"></div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="monthly-affiliate-container">
    <div class="monthly-affiliate-background">
      <div class="monthly-affiliate-text">
        {{ $t('components.plans.gold.card.header.occurrence.monthly') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToggleOcurrence',
  props: {
    user: {
      type: Object,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    computedSelectedPlan() {
      return this.value;
    },
    computedShowQuarterlyFirst() {
      if (this.user.interest == "dropshipping" && this.user.sales_range.length == 1 && this.user.sales_range[0] == 0) {
        return false;
      } else {
        return true;
      }
    },
    computedShowToggle() {
      return !this.user.affiliate_name;
    }
  },
  methods: {
    handlePlanChange(plan) {
      this.$emit('input', plan);
      this.$emit('plan-changed', plan);
    },
    showModal() {
      if(this.user.affiliate_name){
        return;
      }
      if(window.innerWidth < 600){
        this.$modal.show('modal-mobile');
      }else{
        this.$modal.show('modal-desktop');
      }
    }
  }
};
</script>

<style scoped>
.toggle-occurrence-container {
  background-color: #242424;
  border-radius: 4px;
  padding: .4rem;
}

.toggle-occurence {
  position: relative;
}

.toggle-container {
  padding: 0px;
}

.toggle-buttons {
  display: flex;
  position: relative;
  background-color: #242424;
  border-radius: 4px;
  padding: 2px;
}

.ocurrence-monthly,
.ocurrence-quarterly {
  padding: 4px 8px;
  color: #D2D2D2;
  font-weight: 700;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 0.3s ease;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  transition: transform 0.5s ease-in-out;
  z-index: 1;
}

.slide-right {
  transform: translateX(6rem);
}

/* Estilos do container do affiliate */
.monthly-affiliate-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #242424;
  padding: 6px 8px;
  border-radius: 4px;
}

.monthly-affiliate-background {
  background-color: #3A3A3A;
  padding: 4px 8px;
  border-radius: 4px;
}

@media (max-width: 900px) {
  .toggle-occurrence-container {
    margin-top: 2rem;
  }
  
}
</style>
